import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { getMunicipios, getMunicipio } from '@/utils/localizacion'

const url = `${config.URL_BASE}company`

export async function getCompaniesSelect() {
  const request = new RequestAdmin()
  const companies = await request.getList(url, 'Company')
  const result = []
  companies.forEach(company => {
    result.push({ value: company.id, text: company.name })
  })
  return result
}

// eslint-disable-next-line import/prefer-default-export
export async function getCompanies() {
  try {
    const request = new RequestAdmin()
    const municipios = await getMunicipios()
    const list = await request.getList(url, 'Company')
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of list) {
      const filter = municipios.filter(municipio => municipio.id === item.municipio)
      item.obj_municipio = filter.length > 0 ? filter[0] : null
    }
    return list
  } catch (err) {
    console.error('Error en cargar Companies', err)
    return []
  }
}

export async function getEmpresa(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    const item = await request.executeGet(`${url}/${id}`)
    item.obj_municipio = await getMunicipio(item.municipio)
    return item
  } catch (err) {
    console.error('Error en cargar Companie', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateItem(item, tipo) {
  try {
    const request = new RequestAdmin()
    const body = {
      id: item.id,
      name: item.name,
      nit: item.nit,
      phone: item.phone,
      email: item.email,
      municipio: Number(item.municipio),
      active: item.active.value,
    }

    if (tipo === 1) return request.executePostPutPatch(`${url}/${item.id}`, body, 'PATCH')
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en actualizar Empresa', err)
    return []
  }
}
