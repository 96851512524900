<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="8"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              v-model="item.name"
              name="name"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="Nit"
            label-for="nit"
          >
            <b-form-input
              v-model="item.nit"
              name="nit"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="Télefono de Contacto"
            label-for="phone"
          >
            <b-form-input
              v-model="item.phone"
              name="phone"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="Correo de Contacto"
            label-for="email"
          >
            <b-form-input
              v-model="item.email"
              name="email"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group
            label="Estado"
            label-for="active"
          >
            <v-select
              v-model="item.active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="option"
              label="title"
              :disabled="editable"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Departamento"
            label-for="departamento"
          >
            <b-form-select
              v-model="departamento.id"
              :options="departamentos"
              :state="(departamento.id === 0 || departamento.id === '' || departamento.id === null) ? false : true"
              :disabled="editable"
              @change="filterMunicipios"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Municipio"
            label-for="municipio"
          >
            <b-form-select
              v-model="item.municipio"
              :options="municipios"
              :state="(item.municipio === 0 || item.municipio === '' || item.municipio === null) ? false : true"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="changeEditable"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Editar</span>
          </b-button>
        </b-col>
        <b-col
          v-if="!editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateItem"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ getTextButton() }}</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="cancel"
          >
            <feather-icon
              icon="SlashIcon"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <br><br>
        </b-col>
        <b-col
          v-if="showDetalle"
          cols="12"
        >
          <h3>Usuarios</h3>
          <list-table-data
            :table-columns="tableColumns"
            :table-data="usuarios"
            redirect-edit="catalogos-usuario-show"
            redirect-show="catalogos-usuario-show"
            :origen="catalogoName"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import ListTableData from '@/components/ListTableData.vue'
import { getEmpresa, updateCreateItem } from '@/utils/companies'
import { getDepartamento, getDepartamentos } from '@/utils/localizacion'
import { getUsersByCompany } from '@/utils/usuarios'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormSelect,
    vSelect,
    ListTableData,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Empresa',
      item: {
        id: null,
        name: '',
        nit: '',
        phone: '',
        email: '',
        municipio: 0,
        active: {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
      },
      editable: router.currentRoute.params.action !== 'Edit' && router.currentRoute.params.action !== 'Create',
      showDetalle: router.currentRoute.params.id !== null && router.currentRoute.params.id !== undefined,
      departamentos: [],
      municipiosFilter: [],
      municipios: [],
      usuarios: [],
      departamento: {
        id: 0,
        name: null,
      },
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombreCompleto',
        },
        {
          label: 'Rol',
          field: 'role',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Email',
          field: 'email',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      option: [
        {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
        {
          title: 'INACTIVO',
          icon: 'ToggleLeftIcon',
          value: false,
        },
      ],
    }
  },
  async created() {
    const dptos = await getDepartamentos()
    this.departamentos = []
    dptos.forEach(item => {
      this.departamentos.push({ text: item.nombre, value: item.id })
    })
    if (router.currentRoute.params.id === null) {
      this.editable = false
    } else {
      await this.cargarItem()
      this.departamento = this.item.obj_municipio.departamento
      const depto = await getDepartamento(this.item.obj_municipio.departamento.id)
      depto.municipios.forEach(item => {
        this.municipios.push({ text: item.nombre, value: item.id })
      })
      this.usuarios = await getUsersByCompany(this.item.id)
    }
  },
  methods: {
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    async filterMunicipios() {
      this.item.municipio = 0
      this.municipios = []
      const depto = await getDepartamento(this.departamento.id)
      depto.municipios.forEach(item => {
        this.municipios.push({ text: item.nombre, value: item.id })
      })
    },
    async updateItem() {
      let result
      console.log(router.currentRoute.params.action)
      const mensaje = router.currentRoute.params.action === 'Edit' ? 'Actualizado' : 'Creado'
      if (router.currentRoute.params.action === 'Edit') result = await updateCreateItem(this.item, 1)
      else result = await updateCreateItem(this.item, 2)
      if (result !== null) {
        this.$router.replace('/administracion/empresas').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.catalogoName} ${mensaje}!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.catalogoName} ${mensaje} correctamente!`,
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.catalogoName} no ${mensaje}`,
            icon: 'FrownIcon',
            variant: 'warning',
            text: `${this.catalogoName} no ${mensaje}!`,
          },
        })
      }
    },
    async cancel() {
      if (router.currentRoute.params.action === 'Edit' || router.currentRoute.params.action === 'Create') await this.$router.replace('/administracion/empresas')
      else await this.cargarItem()
    },
    async cargarItem() {
      try {
        const result = await getEmpresa(router.currentRoute.params.id)
        if (result !== null) {
          this.item = result
          if (this.item.active === undefined) this.item.active = true
          // this.municipios = this.item.municipios
          this.item.active = this.item.active ? {
            title: 'ACTIVO',
            icon: 'ToggleRightIcon',
            value: true,
          } : {
            title: 'INACTIVO',
            icon: 'ToggleLeftIcon',
            value: false,
          }
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = null
      }
      this.editable = router.currentRoute.params.action !== 'Edit'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}
</style>
